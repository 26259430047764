import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';
export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const testimonialData = [
    {
      testimonialThumb: '/images/yorum1.png',
      testimonialText:
        'İlk defa hizmet aldım ve aldığım hizmetten yardımcı olma duygusunu tam olarak aldığım için kalite tesadüf değildir notumu bırakıyorum :)',
      avatarName: 'Vietra Roleplay',
      avatarDesignation: 'Oyun Sunucusu',
      ratings: '5',
    },
    {
      testimonialThumb: '/images/yorum2.png',
      testimonialText:
        ' Öncelikle devamlı bir müşterileri olmaktan sürekli memnuniyet duyuyorum ve bu memnuniyetim ömürlük olacağınıda rahatça söyleyebilirim,çok güzel ilgilendiler ve ciddi anlamda göze hitap edici ve zevkli işler çıkardıklarınıda söylemek istiyorum buradan tüm yetkililere ve bu işe gönül vermiş herkese çok ama çok teşekkür ediyorum ve saygılarımı sunuyorum',
      avatarName: 'Fin Community',
      avatarDesignation: 'Topluluk',
      ratings: '5',
    },
    {
      testimonialThumb: '/images/yorum3.png',
      testimonialText:
        'Bir gün içerisinde iki paketi bitirdi, yaptığı her detaydan sonra fikrimi istedi ve buna göre şekillendirdi. Rahatça samimi konuşabildiğim ve seri grafikerlerden.',
      avatarName: 'Vatan Roleplay',
      avatarDesignation: 'Oyun Sunucusu',
      ratings: '4.5',
    },
    {
      testimonialThumb: '/images/yorum4.png',
      testimonialText:
        "2022'den beri hizmet aldığım kaliteli bir firma. DisCore olarak tüm tasarımsal ve grafiksel işleri Alpha Grafik ile yapıyoruz ve kullanıcı memnuniyetinde artış sağlıyoruz. Ayrıca revize gibi imkanlarla değişmesini istediğimiz tasarımı kolayca değiştirtebiliyoruz. Destek ekipleri oldukça düşünceli davranıyor ve hizmette sınır tanımıyor. Tavsiye ederim.",
      avatarName: 'DisCore',
      avatarDesignation: 'Uygulama',
      ratings: '5',
    },
    {
      testimonialThumb: '/images/yorum5.png',
      testimonialText:
        '2D ve 3D tasarım istedim,gayet hoş tasarım yaptı kendisi.Tabiki tek grafiker olunca biraz iş uzuyor ama 3 gün ortalama süre diyebilirim.',
      avatarName: 'Eternals Community',
      avatarDesignation: 'Topluluk',
      ratings: '4.5',
    },
  ];
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.testimonialThumb} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}
